// Esta función activa el desplazamiento suave de las secciones del index
function efectoScroll(){
    // Al iniciar la página, posiciona en la parte superior
    setTimeout(function() {
        window.scrollTo(0, 0);
    }, 400);
    
    // Cuando se detecte el scroll
    window.addEventListener("wheel", (event) => {
        event.preventDefault(); // Deshabilita el scroll normal
    
        // Seleccionamos las secciones
        const main = document.querySelector('#mainIndex');
        const sections = main.querySelectorAll('section');
    
        // Sección actual
        let currentSectionIndex = -1; // Si es -1 significa que no se ha encontrado sección visible
    
        // Encuentra la sección actual
        sections.forEach((section, index) => {
            const rect = section.getBoundingClientRect(); // rec: rectángulo dimensional de cada section
        
            if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
                // si la parte superior y la parte inferior de la sección están dentro del área visible de la ventana
                currentSectionIndex = index;
            }
        });
        
        if (currentSectionIndex !== -1) {
            const direction = event.deltaY > 0 ? 1 : -1; // 1 hacia abajo, -1 hacia arriba
            const nextSectionIndex = currentSectionIndex + direction;
        
            if (nextSectionIndex >= 0 && nextSectionIndex < sections.length) {
                // si el índice de la próxima sección está dentro de los límites válidos (es decir, dentro del rango de índices de las secciones existentes)
                // Tenemos en cuenta el margin de cada section para el desplazamiento
                const margin = parseFloat(getComputedStyle(sections[nextSectionIndex]).marginTop);
                const offset = sections[nextSectionIndex].offsetTop - margin; // Calculamos la posición exacta
            
                // Realizamos el scroll
                window.scrollTo({
                    top: offset,
                    behavior: 'smooth'
                });
            }
        } 
    }, { passive: false });
}