// Main function for the scheduling section and Cita button
function initCitas() {
    // Main Cita button
    const btnCitas = document.querySelector('#btnCitas');
    const btnCitasPhase1 = document.querySelector('.phase1');
    const btnCitasPhase2 = document.querySelector('.phase2');

    // Constants in the Schedule Cita section
    const sectScheduleCita = document.querySelector('.sectScheduleCita');
    const btnClose = document.querySelector('#closeSectScheduleCita');
    const btnLogin = document.querySelector('#btnAccess2');

    // Login section
    const sectLogin = document.querySelector('.sectLogin');
    
    // Form to schedule the Cita
    const scheduleForm = document.querySelector('#formScheduleCita');
    const modifyForm = document.querySelector('#formModifyCita');
    
    // Animation on the main button
    btnCitas.addEventListener('mouseover', () => {
        btnCitas.classList.add('activeCitas');
        btnCitas.classList.remove('inactiveCitas');
        btnCitasPhase1.classList.add('hide');
        btnCitasPhase2.classList.remove('hide');
    });
    btnCitas.addEventListener('mouseout', () => {
        btnCitas.classList.remove('activeCitas');
        btnCitas.classList.add('inactiveCitas');
        btnCitasPhase1.classList.remove('hide');
        btnCitasPhase2.classList.add('hide');
    });
    btnCitas.addEventListener('click', () => {
        changeBlur();
        sectScheduleCita.classList.remove('hide');
    });

    // Close section
    btnClose.addEventListener('click', () => {
        changeBlur();
        sectScheduleCita.classList.add('hide');
    });

    // If session is started
    if (scheduleForm != null) scheduleCitaForm(scheduleForm);
    if (modifyForm != null) removeCitaForm(modifyForm);
    
    // If session is not started
    if(btnLogin != null)
    btnLogin.addEventListener('click', () => {
        sectScheduleCita.classList.add('hide');
        sectLogin.classList.remove('hide');
    });
}