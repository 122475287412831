// Sección de registro de sesión
function register(sectLogin, sectRegister){
    const formularioRegistro = document.querySelector('#formRegister');
    const btnRegister = document.querySelector('#btnRegister');
    const btnSubmit = document.querySelector('#btnSubmitRegister');
    const btnClose = document.querySelector('#closeSectRegister');
    
    // Botón ubicado en la sección del login, abre la sección de sign
    btnRegister.addEventListener('click', ()=>{
        sectLogin.classList.add('hide');
        sectRegister.classList.remove('hide');
    });
    
    // Botón de cerrar section sign
    btnClose.addEventListener('click', ()=>{
        sectRegister.classList.add('hide');
        changeBlur();
    });
    
    // Cuando se envié el formulario
    formularioRegistro.addEventListener('submit', (event)=>{
        event.preventDefault(); // Evita el envío del form de forma PREDETERMINADA

        // Se deshabilita el botón de submit
        btnSubmit.disabled = true
        btnSubmit.classList.add('btnDisabled');

        // Señala donde se van a colocar las alerts
        const divAlertsSign = document.querySelector('#alertsRegister');

        // Inputs de HTML
        //const teléfonoInput = document.querySelector('#telRegister');
        const nameInput = document.querySelector('#nameRegister');
        const lastNameInput = document.querySelector('#lastNameRegister');
        const emailInput = document.querySelector('#emailRegister');
        const passwordInput = document.querySelector('#passwordRegister');
        const passwordConfirmInput = document.querySelector('#confirmPasswordRegister');
        
        // Información Teléfono (Input Teléfono)
        const telCountryCode = inputTelRegisterInt.getSelectedCountryData().dialCode;
        let phone = inputTelRegisterInt.getNumber();
        
        // Quita la extensión de país del número de celular
        if (phone.startsWith('+' + telCountryCode)) phone = phone.replace('+' + telCountryCode, '')

        // Datos formulario HTML SANITIZADO por DOMPurify
        const datos = {
            name: DOMPurify.sanitize(nameInput.value.trim()),
            lastName: DOMPurify.sanitize(lastNameInput.value.trim()),
            countryCode: DOMPurify.sanitize(telCountryCode.trim()),
            phone: DOMPurify.sanitize(phone.trim()),
            email: DOMPurify.sanitize(emailInput.value.trim()),
            password: DOMPurify.sanitize(passwordInput.value),
            passwordConfirm: DOMPurify.sanitize(passwordConfirmInput.value)
        };
        
        // Validar datos antes de enviarlos
        setAlert('clear'); // Limpia las alerts
        const regularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Formar regular de un email
        
        if (datos.name == '')                       setAlert('error', 'El nombre es obligatorio');
        if (datos.name.length > 45)                 setAlert('error', 'El nombre es demasiado largo');
        if (datos.lastName.length > 45)               setAlert('error', 'El apellido es demasiado largo');
        if (!inputTelRegisterInt.isValidNumber())    setAlert('error', 'El número de teléfono no es válido');
        if (datos.countryCode == null)                 setAlert('error', 'El código del país es incorrecto');
        if (!regularEmail.test(datos.email))        setAlert('error', 'El correo electrónico no es válido');
        if (datos.email.length > 50)                 setAlert('error', 'El correo es demasiado largo');
        if (datos.password !== datos.passwordConfirm)setAlert('error', 'Las contraseñas deben ser idénticas');
        if (datos.password.length < 8)              setAlert('error', 'La cantidad de caracteres de la contraseña debe ser mayor a 7');
        
        // TODO crear alerts !!
        if(hasAlerts('error')) {
            showAlerts(divAlertsSign, 3000);
            setTimeout(()=>{
                btnSubmit.disabled = false;
                btnSubmit.classList.remove('btnDisabled');
            }, 5000);
            return;
        } // Si existen errores evita su envió

        // Datos a enviar a la API
        const datosToAPI = new FormData();
        datosToAPI.append('name', datos.name);
        datosToAPI.append('lastName', datos.lastName);
        datosToAPI.append('countryCode', datos.countryCode);
        datosToAPI.append('phone', datos.phone);
        datosToAPI.append('email', datos.email);
        datosToAPI.append('password', datos.password);
        datosToAPI.append('passwordConfirm', datos.passwordConfirm);
        
        // URL
        const urlAPI = url + '/api/register';
        
        // options Fetch
        const options = {
            method: 'POST',
            body: datosToAPI,
        }
        
        // Mensaje de espera
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        showAlerts(divAlertsSign, -1); // Dura infinito hasta que se borre

        // Enviar petición API
        fetch(urlAPI, options)
        .then(response => response.json())
        .then(data => {
            setAlert('clear'); // Limpia las alerts
            result = data.result;
            
            if(result){
                // Si hubo un registro exitoso
                setAlert('success', 'Registro exitoso. Te enviamos un correo electrónico a ' + datos.correo + ' para confirmar el registro');
                showAlerts(divAlertsSign, 10000);
            }else{
                btnSubmit.disabled = false;
                if (Array.isArray(data.error)) data.error.forEach(error =>  setAlert('error', error) );
                else setAlert('error', data.error);
                showAlerts(divAlertsSign, 3000);
                btnSubmit.classList.remove('btnDisabled');
            }
        })
        .catch(error => {
            // Muestra el error
            btnSubmit.disabled = false;
            setAlert('clear');
            setAlert('error', error)
            showAlerts(divAlertsSign, 5000);
            btnSubmit.classList.remove('btnDisabled');
        });
        
    });
}