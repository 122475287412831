// Variables globales
const url = window.location.origin;
let inputTelRegister;

// Páginas permitidas
const routes = {
    index: '/',
    search: '/buscador',
    blog: '/blog',
    news: '/noticias',
    location: '/location',
    privacyPolicy: '/politica-privacidad',
    termsConditions: '/terminos-condiciones'
};
const secondaryRoutes = {
    confirmRegistration: '/confirmarRegistro',
    resetPassword: '/restablecer',
    recoverUser: '/recuperarUsuario'
};
const adminRoutes = {
    index: '/admin'
};

// Función principal (main), se ejecuta siempre
document.addEventListener("DOMContentLoaded", () => {
    const currentPath = window.location.pathname;
    
    const isAllowedRoute = Object.values(routes).includes(currentPath);
    const isAllowedSecondaryRoute = Object.values(secondaryRoutes).includes(currentPath);
    const isAllowedAdminRoute = Object.values(adminRoutes).includes(currentPath);

    // Verifica si la URL actual está dentro de las rutas permitidas
    // Estas funciones se encuentran en la carpeta functions
    
    if (isAllowedRoute) {
        
        activateFunctions();
    } else if (isAllowedSecondaryRoute) {
        activateSecondaryFunctions();
    } else if (isAllowedAdminRoute) {
        activateAdminFunctions();
    }
});

function activateFunctions() {
    // Activa el teléfono internacional si existe el formulario de registro
    if (document.querySelector('.sectRegister')) initIntlPhoneInput();

    // Maneja el inicio de sesión y registro de usuarios
    if (document.querySelector('.sectRegister') && document.querySelector('.sectLogin')) initFormsLoginRegister();
    if (document.querySelector('#btnProfile')) logout();
    
    // Habilita el chatBot
    if (document.querySelector('.chatBotBox')) initChatBot();

    // Habilita las citas
    if (document.querySelector('#btnCitas')) initCitas();

    // Habilita las funciones de redes sociales
    if (document.querySelector('#socialMediaBubble')) initSocialMedia();

    // Habilita el menú hamburguesa
    if (document.querySelector('#burgerMenu')) initBurgerMenu();

    // Activa funcionalidades específicas según la página actual
    // Ubicado en principalPages
    
    const pathActions = {
        [routes.index]: initIndexApp,
        [routes.search]: initSearch,
        [routes.blog]: initBlog
    };
    
    if (pathActions[window.location.pathname]) {
        pathActions[window.location.pathname]();
    }
}

function activateSecondaryFunctions() {
    // Habilita el chatBot
    if (document.querySelector('.chatBotBox')) initChatBot();

    // Elimina el botón de citas si existe
    const citaButton = document.querySelector('#btnCitas');
    if (citaButton) citaButton.remove();

    // Activa las funciones de redes sociales
    if (document.querySelector('#socialMediaBubble')) initSocialMedia();

    // Elimina el menú hamburguesa si existe
    const burgerMenu = document.querySelector('#menuPrincipal');
    if (burgerMenu) burgerMenu.remove();
}

function activateAdminFunctions() {
    const sectLogin = document.querySelector('.sectLogin');
    if (sectLogin) login(sectLogin);
    initAdminSession();
    optionsAdmin();
    
}
