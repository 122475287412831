let possibleQueries = [];

// Función principal
function initChatBot(){
    // Constantes y variables
    const generalDiv = document.querySelector('.chatBotBox');
    const bubble = document.querySelector('.bubble');
    const btnClose = document.querySelector('#btnCloseChatBotBox');
    const trayDiv = document.querySelector('#messageInboxChatBot');
    const optionsDiv = document.querySelector('#optionsChatBot');
    const headChatBotBox = document.querySelector('.chatBotHeader');
    
    // Si se da click sobre el icono de ChatBot
    bubble.addEventListener('click', (e)=>{
        // Para animaciones del CSS
        headChatBotBox.classList.remove('hide');
        generalDiv.classList.add('active');
        generalDiv.classList.remove('inactive');
        optionsDiv.classList.remove('hide');
        startChatBot();
    });

    btnClose.addEventListener('click', ()=>{
        // Si no está en modo burbuja, cierra el diálogo y borra todo
        // Para animaciones del CSS
        headChatBotBox.classList.add('hide');
        generalDiv.classList.add('inactive');
        generalDiv.classList.remove('active');
        trayDiv.classList.add('hide');
        optionsDiv.classList.add('hide');

        clearMessages();
        clearOptions();
    });
}

// Muestra el mensaje de inicio, también sirve para empezar de nuevo el chat
function startChatBot(){
    // Estas son las opciones que muestra ChatBot
    possibleQueries = ['Costos', 'Atención', 'Agendar cita', 'Contacto'];

    showMessage('Hola, soy un asistente virtual, me gustaría ayudarte.', 'chatBotMessage');
    showMessage('Selecciona alguna de las siguientes opciones:', 'chatBotMessage');
    showOptions(possibleQueries);
}

// Muestra un mensaje por parte del chatbot
function showMessage(message, sender) {
    // Variables y constantes
    const trayDiv = document.querySelector('#messageInboxChatBot');
    // Elementos HTML
    const messageDiv = document.createElement('DIV');

    // Se muestra la bandeja de mensajes
    trayDiv.classList.remove('hide');

    messageDiv.classList.add('message');
    messageDiv.classList.add(sender);
    messageDiv.textContent = message;
    trayDiv.appendChild(messageDiv);
}

// Borra todos los mensajes de la bandeja
function clearMessages(){
    const trayDiv = document.querySelector('#messageInboxChatBot');
    const messages = trayDiv.children;
    
    Array.from(messages).forEach(message => message.remove());
}

// Muestra las posibles preguntas del usuario
function showOptions(){
    const trayDiv = document.querySelector('#optionsChatBot');

    possibleQueries.forEach(option => {
        // Crea el elemento HTML
        const messageDiv = document.createElement('BUTTON');

        messageDiv.classList.add('option');
        messageDiv.textContent = option;

        trayDiv.appendChild(messageDiv);

        // Escuchar evento si se selecciona alguna opción
        messageDiv.addEventListener('click', ()=>{
            optionSelected(messageDiv);
        });
    });
}

// Borra las posibles preguntas del usuario
function clearOptions(){
    const trayDiv = document.querySelector('#optionsChatBot');
    const messages = trayDiv.children;
    
    Array.from(messages).forEach(message => message.remove());
}

// Muestra respuestas según la opción seleccionada
function optionSelected(optionDiv){
    // Borra todo
    clearMessages();
    clearOptions();

    const option = optionDiv.textContent;
    let chatbotResponse = '';
    let userResponse = '';

    // Diferentes opciones
    switch (option) {
        case 'Costos':
            userResponse = 'Costos';
            chatbotResponse = 'Estos son nuestros precios: ';
            possibleQueries = ['Empezar de nuevo'];
            break;
        case 'Atención':
            userResponse = 'Atención';
            chatbotResponse = 'Nuestro horario es: ';
            possibleQueries = ['Empezar de nuevo'];
            break;
        case 'Agendar cita':
            userResponse = 'Agendar cita';
            chatbotResponse = 'Aquí puedes agendar tu cita: ';
            possibleQueries = ['Empezar de nuevo'];
            break;
        case 'Contacto':
            userResponse = 'Contacto';
            chatbotResponse = 'Comunícate al: ';
            possibleQueries = ['Empezar de nuevo'];
            break;
        case 'Empezar de nuevo':
            startChatBot();
            return;
        default:
            chatbotResponse = 'Hubo un error.';
            possibleQueries = ['Empezar de nuevo'];
            break;
    }

    showMessage(userResponse, 'userMessage');
    showMessage(chatbotResponse, 'chatBotMessage');
    showOptions();
}
