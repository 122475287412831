function teamIntro() {
    
    const divMejia = document.querySelector('.mejia');
    const divMuñoz = document.querySelector('.munoz');
    const divJulio = document.querySelector('.julio');

    divMejia.addEventListener('click', ()=>{
        const title  = 'Médico (RM. 1061688767) \n Universidad Tecnológica de Pereira. - Pereira, Risaralda. Especialista en Derecho Médico y Sanitario \nUniversidad del Rosario - Bogotá D.C - Periodo 2018 \nActa Individual de Grado No. 11243-55937';
        const description   = 'Médico responsable, autónomo, respetuoso de la vida, de la dignidad humana y de los derechos del paciente. Capacitado para resolver los principales problemas de salud con un enfoque integral de los conceptos biológico, psicológico y social. Estudioso, buscando actualizar en forma permanente sus conocimientos y abierto a los cambios establecidos en las sociedades científicas. Capaz de dirigir, programar, supervisar y evaluar las actividades del personal a su cargo. Experiencia en el área asistencial, servicio de urgencias adultos, cuidado crítico adultos, consulta externa general y de programas (Controles de crecimiento y desarrollo, gestantes y pacientes crónicos).';

        const socialPhone  = 'EjemploT';
        const socialEmail  = 'EjemploC';

        // Si las redes son '' vacías no mostrará el icono.
        showPersonInfo(title , description, socialPhone , socialEmail );
        selectPerson(divMejia);
    });
    divMuñoz.addEventListener('click', ()=>{
        const title  = 'Abogado Universidad Del Cauca (Tarjeta Profesional No. 47.581 MINISTERIO DE JUSTICIA) Especialista en Derecho Penal y Criminología - Universidad Libre.';
        const description  = 'Diplomado: “Criminalística, Sistema Penitenciario y Medicina Legal” Abogado Sustanciador Personería Delegada en lo Penal - Popayán Personero Delegado en lo Penal. Encargado Fiscal Delegado Ante Jueces Municipales y Promiscuos de l  Dirección Seccional de Fiscalías de Popayán. Abogado Defensoría del Pueblo.';

        const socialPhone  = 'EjemploT';
        const socialEmail  = 'EjemploC';
        showPersonInfo(title , description, socialPhone , socialEmail );
        selectPerson(divMuñoz);
    });
    divJulio.addEventListener('click', ()=>{
        const description  = 'Abogado, Especialista en Derecho Administrativo. Especialista en Gobierno y Políticas Públicas Universidad del Cauca. Docente Universitario en el área de Derecho Administrativo y Derecho de Daños. Experiencia en el sector público desde la gestión y desarrollo del talento humano, función pública, y control interno disciplinario. \nAsesor y consultor en la rama del Derecho Público y Derecho Civil.';

        const socialPhone  = 'EjemploT';
        const socialEmail  = 'EjemploC';

        showPersonInfo('', description, socialPhone , socialEmail );
        selectPerson(divJulio);
    });
}

// Muestra la información de la persona seleccionada en el DOM
function showPersonInfo(title, description, phone, email) {
    const existingPersonInfo = document.querySelector('.personInfo');
    if (existingPersonInfo) existingPersonInfo.remove();

    const divTop = document.querySelector('.topPart');
    const personArticle = document.createElement('article');
    personArticle.classList.add('personInfo');

    const divInfo = document.createElement('DIV');
    const h3 = document.createElement('H3');
    const p = document.createElement('P');

    divInfo.classList.add('textInfo');
    divInfo.appendChild(h3);
    divInfo.appendChild(p);

    // Para los iconos de redes sociales
    const socialBar = document.createElement('DIV');
    socialBar.classList.add('socialMediaInfo');
    /*
    if (phone !== '') {
        const phoneDiv = document.createElement('DIV');
        const phoneLink = document.createElement('A');
        const phoneIcon = document.createElement('i');
        phoneIcon.classList.add('fas', 'fa-phone'); // Clases de Font Awesome
        phoneLink.href = `tel:${phone}`;

        phoneLink.appendChild(phoneIcon);
        phoneDiv.appendChild(phoneLink);
        socialBar.appendChild(phoneDiv);
    }

    if (email !== '') {
        const emailDiv = document.createElement('DIV');
        const emailLink = document.createElement('A');
        const emailIcon = document.createElement('i');
        emailIcon.classList.add('fas', 'fa-envelope'); // Clases de Font Awesome
        emailLink.href = `mailto:${email}`;

        emailLink.appendChild(emailIcon);
        emailDiv.appendChild(emailLink);
        socialBar.appendChild(emailDiv);
    }
        */

    // Dividir el texto del h3 y p en líneas con saltos de línea
    const titleLines = title.split('\n');
    titleLines.forEach((line, index) => {
        h3.appendChild(document.createTextNode(line));
        if (index < titleLines.length - 1) h3.appendChild(document.createElement('br'));
    });

    const descriptionLines = description.split('\n');
    descriptionLines.forEach((line, index) => {
        p.appendChild(document.createTextNode(line));
        if (index < descriptionLines.length - 1) p.appendChild(document.createElement('br'));
    });

    personArticle.appendChild(socialBar);
    personArticle.appendChild(divInfo);
    divTop.appendChild(personArticle);
}


// Selecciona la persona, destacándola en la interfaz
function selectPerson(personDiv) {
    const people = document.querySelectorAll('.person');
    people.forEach(person => {
        person.classList.remove('selectedPerson');
        person.classList.add('unselectedPerson');
    });

    personDiv.classList.add('selectedPerson');
    personDiv.classList.remove('unselectedPerson');
    personDiv.style.animation = 'slideIn 0.5s ease';

    setTimeout(() => {
        personDiv.style.animation = '';
    }, 500); // Duración de la animación
    reorderPeople();
}

// Reorganiza las personas en la interfaz
function reorderPeople() {
    const people = document.querySelectorAll('.person');
    people.forEach(person => {
        if (person.classList.contains('selectedPerson')) {
            person.parentNode.prepend(person);
        } else {
            person.parentNode.append(person);
        }
    });
}