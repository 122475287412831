// Funcionalidad del buscador usando la aproximación de Levenshtein
function searchFunctionality() {
    // Aquí se almacenan todas las posibles búsquedas, modificar para añadir más.
    const possibleSearches = {
        'Precios': '/#sectServices',
        'Servicios': '/#sectServices',
        'Contacto': '/#contact',
        'Ubicación': '/location',
        'Términos y Condiciones': '/terminos-condiciones',
        'Términos': '/terminos-condiciones',
        'Condiciones': '/terminos-condiciones',
        'Blog': '/blog',
        'Noticias': '/noticias',
        'Política Privacidad': '/politica-privacidad',
        'Tratamiento de Datos': '/politica-privacidad',
        'Datos Personales': '/politica-privacidad',
    };

    // DOM
    const searchInput = document.getElementById('search');
    const searchForm = document.getElementById('searchForm');

    // Cuando el usuario envíe la petición
    searchForm.addEventListener('submit', function(event) { event.preventDefault(); });

    // Captura la entrada del usuario mientras escribe
    searchForm.addEventListener('input', function(event) {
        event.preventDefault();

        const searchTerm = searchInput.value.trim();
        const closeMatches = findCloseWords(searchTerm, possibleSearches);

        // Muestra las palabras cercanas en el DOM
        displayCloseMatches(closeMatches);
    });
}

// Busca las palabras más cercanas a lo que digitó el usuario
function findCloseWords(userInput, possibleSearches) {
    let maxDistance = 3;
    let closeMatches = {};

    let found = false;

    // Bucle while para reiniciar si closeMatches está vacío
    while (!found && maxDistance <= 15 && userInput != '') {
        // Limpiamos closeMatches para una nueva iteración
        closeMatches = {};

        // Itera sobre las posibles búsquedas
        for (let word in possibleSearches) {
            if (possibleSearches.hasOwnProperty(word)) {
                const distance = calculateLevenshteinDistance(userInput, word);
                if (distance <= maxDistance) {
                    closeMatches[word] = possibleSearches[word];
                    found = true;
                    maxDistance = 3;
                }
            }
        }

        // Si closeMatches está vacío, aumenta maxDistance en 2 para la próxima iteración
        if (Object.keys(closeMatches).length === 0) maxDistance += 2;
    }

    return closeMatches;
}

// Quita las tildes de las cadenas
function removeAccents(str) {
    const accents = {
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
        'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U'
    };
    return str.replace(/[áéíóúÁÉÍÓÚ]/g, letter => accents[letter]);
}

// Calcula la distancia de Levenshtein entre dos cadenas
function calculateLevenshteinDistance(a, b) {
    a = removeAccents(a.toLowerCase());
    b = removeAccents(b.toLowerCase());

    // Crea una matriz de tamaño (a.length + 1) x (b.length + 1) e inicializa todas las celdas en 0
    const matrix = Array.from(Array(a.length + 1), () => Array(b.length + 1).fill(0));

    // Inicializa la primera columna de la matriz con valores de 0 a a.length
    for (let i = 0; i <= a.length; i++) matrix[i][0] = i;

    // Inicializa la primera fila de la matriz con valores de 0 a b.length
    for (let j = 0; j <= b.length; j++) matrix[0][j] = j;

    // Llena el resto de la matriz usando el algoritmo de distancia de Levenshtein
    for (let i = 1; i <= a.length; i++) {
        for (let j = 1; j <= b.length; j++) {
            // Calcula el costo de la operación (0 si los caracteres son iguales, 1 si son diferentes)
            const cost = a[i - 1] === b[j - 1] ? 0 : 1;
            // Calcula el valor en la celda actual usando las operaciones de inserción, eliminación y sustitución
            matrix[i][j] = Math.min(
                matrix[i - 1][j] + 1,       // Operación de eliminación
                matrix[i][j - 1] + 1,       // Operación de inserción
                matrix[i - 1][j - 1] + cost // Operación de sustitución
            );
        }
    }

    // El valor en la esquina inferior derecha de la matriz representa la distancia de Levenshtein entre las cadenas a y b
    return matrix[a.length][b.length];
}

// Itera sobre las palabras cercanas y las muestra en el DOM con sus respectivos enlaces
function displayCloseMatches(matches) {
    const searchResultsDiv = document.getElementById('searchResult');
    searchResultsDiv.innerHTML = '';

    if (matches == null || matches == {}) {
        searchResultsDiv.textContent = 'No se encontraron resultados cercanos.';
    } else {
        for (let word in matches) {
            const resultDiv = document.createElement('a');
            resultDiv.classList.add('result');
            resultDiv.href = matches[word];
            resultDiv.textContent = word;
            searchResultsDiv.appendChild(resultDiv);
        }
    }
}
