function sectMissionVision() {
    const divGeneral = document.querySelector('.general');
    const divLearnMore = document.querySelector('.learnMore');
    const divMissionVision = document.querySelector('.missionVision');
    const divObjectives = document.querySelector('.objectives');
    const btnLearnMore = document.querySelector('#learnMoreSectMissionVision');
    
    const btnPassObjetives = document.getElementById("btnGoToObjectives");
    const btnPassMissionVision = document.getElementById("btnGoToMissionVision");

    // Al hacer clic en "Learn More", se oculta la sección general y se muestra la de "Learn More"
    btnLearnMore.addEventListener('click', () => {
        divGeneral.classList.add('hide');
        divLearnMore.classList.remove('hide');
    });

    // Al hacer clic en el botón de ">", se oculta la sección de Objetivos y se muestra la de Misión y Visión
    btnPassMissionVision.addEventListener('click', () => {
        divObjectives.classList.add('hide');
        divMissionVision.classList.remove('hide');
    });

    // Al hacer clic en el botón de "<", se oculta la sección de Misión y Visión y se muestra la de Objetivos
    btnPassObjetives.addEventListener('click', () => {
        divObjectives.classList.remove('hide');
        divMissionVision.classList.add('hide');
    });
}
