function changeImages() {
    const imagesContainer = document.querySelector('.mainImg');
    // Se seleccionan las imágenes para rotar
    const firstImage = imagesContainer.children[0];
    const secondImage = imagesContainer.children[1];

    if (firstImage.classList.contains('hide')) {
        firstImage.classList.remove('hide');
        secondImage.classList.add('hide');
        // Aplica la animación a firstImage
        firstImage.style.animation = 'fadeInOut 1s ease-out';
        // Elimina la animación de secondImage si la tiene
        secondImage.style.animation = '';
    } else {
        firstImage.classList.add('hide');
        secondImage.classList.remove('hide');
        // Aplica la animación a secondImage
        secondImage.style.animation = 'fadeInOut 1s ease-out';
        // Elimina la animación de firstImage si la tiene
        firstImage.style.animation = '';
    }
}
