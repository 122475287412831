
// Form functionality
function scheduleCitaForm(form) {
    const inputDate = document.getElementById('dateScheduleCita');
    const inputTime = document.getElementById('timeScheduleCita');
    const alertDiv = document.querySelector('#alertsScheduleCita');
    
    const btnScheduleCita = document.querySelector('#btnScheduleCita');

    // Activate functionalities of fields
    setCitaDate(inputDate, alertDiv);
    setCitaTime(inputTime, alertDiv);

    // If form is submitted
    form.addEventListener('submit', (e) => {
        e.preventDefault();

        // Disable button
        btnScheduleCita.disabled = true;
        btnScheduleCita.classList.add('btnDisabled');

        // Inputs
        const inputDate = document.querySelector('#dateScheduleCita');
        const inputTime = document.querySelector('#timeScheduleCita');
        
        // HTML form data SANITIZED by DOMPurify
        const dataForm = {
            date: DOMPurify.sanitize(inputDate.value),
            time: DOMPurify.sanitize(inputTime.value)
        }

        // Clear and remove Alerts
        setAlert('clear');
        removeAlerts();
        
        // Regular expressions to validate date (YYYY-MM-DD) and time (HH:MM)
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

        if (!dateRegex.test(dataForm.date))        
            setAlert('error', 'La fecha seleccionada no es válida');
        if (!timeRegex.test(dataForm.time))        
            setAlert('error', 'La hora seleccionada no es válida');

        // If there are errors, do not send the fetch
        if (hasAlerts('error')) {
            showAlerts(alertDiv, 3000);
            setTimeout(() => {
                btnScheduleCita.disabled = false;
                btnScheduleCita.classList.remove('btnDisabled');
            }, 5000);
            return;
        }
        
        // Data to send to the API
        const dataToAPI = new FormData();
        dataToAPI.append('date', dataForm.date);
        dataToAPI.append('time', dataForm.time);

        // URL
        const apiURL = url + '/api/scheduleCita';

        // Fetch options
        const options = {
            method: 'POST',
            body: dataToAPI,
        }

        // Waiting message
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        hasAlerts(alertDiv, -1); 

        // Send API request
        fetch(apiURL, options)
        .then(response => response.json())
        .then(data => {
            // If registration is successful
            setAlert('clear');
            removeAlerts();
            console.log( data );
            
            if (data.result == 'true') {
                // Successful login
                setAlert('success', 'Se ha creado tu cita correctamente')
                showAlerts(alertDiv, 5000);
                setTimeout(() => location.reload(), 3000);
            } else {
                // Login error
                btnScheduleCita.disabled = false;
                btnScheduleCita.classList.remove('btnDisabled');
                
                if (Array.isArray(data.error)) data.error.forEach(error =>  {
                    setAlert('error', error)
                    showAlerts(alertDiv, 7000);
                });
                else {
                    setAlert('error', 'Hubo un problema');
                    showAlerts(alertDiv, 5000);
                }
            }
        })
        .catch(error => {
            // Show the error
            btnScheduleCita.disabled = false;
            btnScheduleCita.classList.remove('btnDisabled');

            setAlert('clear');
            setAlert('error', error)
            showAlerts(alertDiv, 5000);
        });
    });
    
}



// Comprueban que la fecha y la hora sean válidas
function setCitaDate(inputDate, alertDiv) {
    // Get current date and add one day
    let minDate = new Date();
    minDate.setDate(minDate.getDate() + 1); // Add one day
    
    // Get current date and add one month
    let maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 1);
    
    // Limit date range
    inputDate.setAttribute('min', minDate.toISOString().split("T")[0]);
    inputDate.setAttribute('max', maxDate.toISOString().split("T")[0]);

    // Set date in information
    const minDateSpan = document.querySelector('#minDate');
    const maxDateSpan = document.querySelector('#maxDate');
    minDateSpan.textContent = minDate.toISOString().split("T")[0].replace('-', '/').replace('-', '/');
    maxDateSpan.textContent = maxDate.toISOString().split("T")[0].replace('-', '/').replace('-', '/');

    // Listen to date changes
    inputDate.addEventListener('input', () => {
        const selectedDate = new Date(inputDate.value);
        removeAlerts();
        setAlert('clear');
        
        if (!isValidCitaDate(selectedDate, minDate, maxDate)) {
            setAlert('error', 'Elije una fecha válida');
            hasAlerts(alertDiv, 5000);
        }
    });
}

function setCitaTime(inputTime, alertDiv) {
    const minTime = '08:00';
    const maxTime = '18:00';

    // Insert information in the info spans
    const minTimeSpan = document.querySelector('#mintime');
    const maxTimeSpan = document.querySelector('#maxtime');
    minTimeSpan.textContent = minTime;
    maxTimeSpan.textContent = maxTime;
    
    // Listen to time changes
    inputTime.addEventListener('input', () => {
        removeAlerts();
        setAlert('clear');
        if (!isValidCitaTime(inputTime, minTime, maxTime)) {
            setAlert('error', 'Elije una hora entre las 8:00 a.m. hasta las 6:00 p.m. hora colombiana.');
            hasAlerts(alertDiv, 5000);
        }
    });
}


// Functions that return whether the date and time are valid for scheduling the Cita
function isValidCitaDate(selectedDate, minDate, maxDate) {
    const dayOfWeek = selectedDate.getDay();

    // Disable non-working days (Saturday and Sunday)
    if (dayOfWeek === 5 || dayOfWeek === 6) return false;

    return (selectedDate >= minDate && selectedDate <= maxDate);
}

function isValidCitaTime(inputTime, minTime, maxTime) {
    const selectedTime = inputTime.value;
    
    return (selectedTime > minTime && selectedTime < maxTime)
}
