function cancelCita(btnCancelCita) {
    btnCancelCita.addEventListener('click', (e)=>{
        // Obtener la URL actual
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('userId');
        
        // Se deshabilita el botón de submit
        btnCancelCita.disabled = true;
        btnCancelCita.classList.add('btnDisabled');

        // Señala donde se van a colocar las alerts
        const divAlerts = document.querySelector('#alertsAdmin');
        
        // Validar datos antes de enviarlos
        setAlert('clear'); // Limpia las alerts

        // Datos a enviar a la API
        const datosToAPI = new FormData();
        datosToAPI.append('userId', DOMPurify.sanitize(id));
        
        // URL
        const urlAPI = url + '/api/admin/cancelCita';
        
        // Opciones Fetch
        const opciones = {
            method: 'POST',
            body: datosToAPI,
        }
        
        // Mensaje de espera
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        showAlerts(divAlerts, -1); // Dura infinito hasta que se borre
        
        // Enviar petición API
        fetch(urlAPI, opciones)
        .then(respuesta => respuesta.json())
        .then(data => {
            // Si hubo un registro exitoso
            setAlert('clear'); // Limpia las alerts
            if(data['result'] == "true"){
                // Login Exitoso
                setAlert('success', 'Se ha cancelado la cita, y se ha removido de Google')
                showAlerts(divAlerts, 5000);
                setTimeout(() => location.reload(), 3000);
            }else{
                // Login erroneo
                btnCancelCita.disabled = false;
                btnCancelCita.classList.remove('btnDisabled');
                
                if (Array.isArray(data.error)) data.error.forEach(error =>  {
                    setAlert('error', error)
                });
                else {
                    setAlert('error', 'Hubo un problema');
                }
                showAlerts(divAlerts, 5000);
            }
        })
        .catch(error => {
            // Muestra el error
            btnCancelCita.disabled = false;
            btnCancelCita.classList.remove('btnDisabled');

            setAlert('clear');
            setAlert('error', error)
            showAlerts(divAlerts, 5000);
        });
    });
}