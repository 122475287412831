function recover(btnRecover) {
    const regularEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Formar regular de un correo
    
    btnRecover.addEventListener('click', (e)=>{
        
        e.preventDefault();
        const email = document.querySelector('#emailLogin').value;
        const divAlerts = document.querySelector('#alertsLogin');
        setAlert('clear');

        // Verificar el correo
        if (email == '') setAlert('error', 'El campo de correo electrónico es obligatorio');
        else if (!regularEmail.test(email)) setAlert('error', 'El correo electrónico no es válido');
        
        if (hasAlerts('error')){
            showAlerts(divAlerts, 3000);
            return;
        }

        // Si no hay errores enviar el correo
        sendEmailConfirm(email);
    });
}

// Reenvía el correo de confirmación
function sendEmailConfirm(email){
    const divAlerts = document.querySelector('#alertsLogin');
    const btnRecoverPassword = document.querySelector('#btnRecoverPassword');
    const btnSubmit = document.querySelector('#btnSubmitLogin');
    
    btnRecoverPassword.disabled = true;
    btnSubmit.disabled = true;
    btnSubmit.classList.add('btnDisabled');

    // Adjuntar email
    const dataToAPI = new FormData();
    dataToAPI.append('email', email);
    
    // URL
    const urlAPI = url + '/api/sendRecoverEmail';
    
    // options Fetch
    const options = {
        method: 'POST',
        body: dataToAPI
    }

    // Mensaje de espera
    setAlert('clear');
    setAlert('info', 'Por favor espera');
    showAlerts(divAlerts, -1); // Dura infinito hasta que se borre
    
    // Enviar petición API
    fetch(urlAPI, options)
    .then(checkResponseRecover)
    .catch(catchResponseRecoverError);

    // Comprueba la response
    function checkResponseRecover(response) {
        return response.json()
            .then(data => {
                setAlert('clear'); // Limpia las alerts
                console.log( data );
                
                if (data['result'] == 'true') {
                    setAlert('success', 'Se ha enviado un correo electrónico a su dirección');
                    showAlerts(divAlerts, 10000);
                }
                else {
                    btnSubmit.disabled = false;
                    btnRecoverPassword.disabled = false;
                    btnSubmit.classList.remove('btnDisabled');
                    errors.forEach(error => setAlert('error', error));
                    showAlerts(divAlerts, 3000);
                }
            });
    }

    function catchResponseRecoverError(error) {
        btnSubmit.disabled = false;
        btnRecoverPassword.disabled = false;
        btnSubmit.classList.remove('btnDisabled');

        // Muestra el error
        setAlert('clear');
        setAlert('error', error);
        showAlerts(divAlerts, 5000);
    }

}

