// Reenvía el correo de confirmación
function forwardConfirmEmail(email){
    const btnSubmit = document.querySelector('#btnSubmitLogin');
    const divAlerts = document.querySelector('#alertsLogin');
    const sectionSesión = document.querySelector('.sectLogin');
    let btnForwardConfirmEmail = document.querySelector('#btnForwardConfirmEmail');


    // Se crea el botón para reenviar el correo
    if(btnForwardConfirmEmail == null ){
        btnForwardConfirmEmail = document.createElement('button');
        btnForwardConfirmEmail.classList.add('btnLink');
        btnForwardConfirmEmail.setAttribute('id', 'btnForwardConfirmEmail');
        btnForwardConfirmEmail.textContent = 'Reenviar Correo de Confirmación';
        sectionSesión.appendChild(btnForwardConfirmEmail);
    }
    


    btnForwardConfirmEmail.addEventListener('click', (e)=>{
        e.preventDefault();
        btnForwardConfirmEmail.remove();
        
        btnSubmit.disabled = true;
        btnSubmit.classList.add('btnDisabled');

        // Adjuntar correo
        const dataToAPI = new FormData();
        dataToAPI.append('email', email);
        
        // URL
        const urlAPI = url + '/api/forwardConfirmEmail';
        
        // options Fetch
        const options = {
            method: 'POST',
            body: dataToAPI
        }
        
        // Mensaje de espera
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        showAlerts(divAlerts, -1); // Dura infinito hasta que se borre
        
        // Enviar petición API
        fetch(urlAPI, options)
        .then(response => response.json())
        .then(data => {
            setAlert('clear'); // Limpia las alerts
            // Si hubo conexión exitosa
            console.log( data );
            
            if(data['result'] == "true"){
                setAlert('success', 'Se ha enviado un correo electrónico a su dirección')
                showAlerts(divAlerts, 10000);
            }else{
                btnSubmit.disabled = false;
                btnSubmit.classList.remove('btnDisabled');
                setAlert('error', data.error)
                showAlerts(divAlerts, 3000);
            }
        })
        .catch(error => {
            btnSubmit.disabled = false;
            btnSubmit.classList.remove('btnDisabled');
            // Muestra el error
            setAlert('clear');
            setAlert('error', error)
            showAlerts(divAlerts, 5000);
        });
    });
}