function initSocialMedia() {
    const socialMediaDiv = document.querySelector('#socialMediaBubble .socialNetworks');
    const iconSvg = document.querySelector('#socialMediaBubble .icon>i');

    // Deshabilita los clics al inicio
    socialMediaDiv.style.pointerEvents = 'none';

    // Deshabilita los clics durante la animación del icono
    iconSvg.addEventListener('animationstart', () => {
        socialMediaDiv.style.pointerEvents = 'none';
    });

    // Habilita los clics cuando la animación de deslizamiento hacia la izquierda termina
    socialMediaDiv.addEventListener('animationend', (e) => {
        if (e.animationName === "slideLeft") {
            socialMediaDiv.style.pointerEvents = 'auto'; 
        }
    });
}
