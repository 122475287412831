function initBlog() {
    const readMoreBtn = document.querySelector('#btnReadMoreBlog');
    const firstPart = document.querySelector('.blogFirstPart');
    const secondPart = document.querySelector('.blogSecondPart');
    const blogImg = document.querySelector('.blogImg');

    // Evento para mostrar más contenido del blog al hacer clic en "Leer más"
    readMoreBtn.addEventListener('click', () => {
        firstPart.classList.add('hide');
        secondPart.classList.remove('hide');
        readMoreBtn.classList.add('hide');
        blogImg.classList.add('hide');
        secondPart.scrollIntoView({ behavior: 'smooth' });
    });
}
