// Animación de los tres puntos suspensivos duración infinita
function pointsAnimation(){
    const placeholder = document.getElementById('search');
    let dots = '';
    setInterval(() => {
        if (dots === '...') {
            dots = '';
        } else {
            dots += '.';
        }
        placeholder.placeholder = `Escribe aquí tu duda${dots}`;
    }, 450); // Cambia los puntos suspensivos cada 0.45 segundos (450 milisegundos)
}