
function removeCitaForm(form) {
    const divAlerts = document.querySelector('#alertsScheduleCita');
    const btnSubmit = document.querySelector('#btnRemoveCita');

    form.addEventListener('submit', (e) => {
        e.preventDefault();

        // URL
        const apiURL = url + '/api/removeCita';

        // Disable button
        btnSubmit.disabled = true;
        btnSubmit.classList.add('btnDisabled');

        // Fetch options
        const options = {
            method: 'POST'
        }

        // Waiting message
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        hasAlerts(divAlerts, -1); // Lasts indefinitely

        // Send API request
        fetch(apiURL, options)
        .then(response => response.json())
        .then(data => {
            // If registration is successful
            setAlert('clear'); // Clears alerts
            removeAlerts();
            if (data.result == 'true') {
                // Successful login
                setAlert('success', 'Se ha cancelado la cita correctamente')
                showAlerts(divAlerts,5000)
                setTimeout(() => location.reload(), 3000);
            } else {
                // Login error
                btnSubmit.disabled = false;
                btnSubmit.classList.remove('btnDisabled');
                
                if (Array.isArray(data.error)) data.error.forEach(error =>  {
                    setAlert('error', error)
                });
                else {
                    setAlert('error', 'Hubo un problema');
                    showAlerts(divAlerts, 5000);
                }
            } 
        })
        .catch(error => {
            // Show the error
            btnSubmit.disabled = false;
            btnSubmit.classList.remove('btnDisabled');

            setAlert('clear');
            setAlert('error', error)
            showAlerts(divAlerts, 5000)
        });
    });
}