// Función started
function initAdminSession(){
    const sectLogin = document.querySelector('.sectLogin'); // field HTML para iniciar sesión
    const btnProfile = document.querySelector('#btnProfile'); // Botón para abrir sectionSesión
    
    if(sectLogin != null) {
        loginAdmin(sectLogin);
        changeBlur(); // Activa o remueve el efecto blur
    } // Equivale a la sección de inicio de sesión
    
    if(btnProfile != null) logout(btnProfile); // Si btnProfile existe significa que tiene sesión iniciada
}