// Función started
function initFormsLoginRegister(){
    const sectLogin = document.querySelector('.sectLogin'); // field HTML para iniciar Login
    const sectRegister = document.querySelector('.sectRegister'); // field HTML para registrar
    const btnRecover = document.querySelector('#btnRecoverPassword'); // Botón para la recuperación de
    
    if(sectLogin) login(sectLogin); // Equivale a la sección de inicio de Login
    if(sectRegister) register(sectLogin, sectRegister); // Equivale a la sección de registro
    if(btnRecover) recover(btnRecover);
}

