function initIntlPhoneInput(){
    // Usaremos la api de intl-tel-input
    const inputTelRegister = document.querySelector("#telRegister");
    // Configuración, leer documentación
    inputTelRegisterInt = window.intlTelInput(inputTelRegister, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        preferredCountries: ["co", "ar", "bo", "cl", "ec", "mx", "pa", "ve", "cr", "pe"], // Muestra solo los países que tienen el idioma en español
        initialCountry: "co", // Código ISO2 de Colombia es "co"
        separateDialCode: false,
        autoPlaceholder: true
    });

    // Activa el autocompletado
    inputTelRegister.setAttribute('autocomplete', 'tel-national');
}