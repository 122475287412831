// Cerrar Login
function logout(){
    const btnProfile = document.getElementById('btnProfile')
    
    btnProfile.addEventListener('click', ()=>{
        // URL
        const urlAPI = url + '/api/logout';
        
        // Opciones Fetch
        const opciones = {
            method: 'POST',
        }
        
        // Enviar petición API
        fetch(urlAPI, opciones);
        location.reload();
    });
}