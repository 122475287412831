// Sección de inicio de sesión
function login(sectLogin){
    const form = document.querySelector('#formLogin');
    const btnAcceder = document.querySelector('#btnAccessLogin');
    const btnSubmit = document.querySelector('#btnSubmitLogin');
    const btnClose = document.querySelector('#closeSectLogin');

    // Botón de Perfil
    btnAcceder.addEventListener('click', ()=>{
        sectLogin.classList.remove('hide');
        changeBlur();
    });

    // Botón de cerrar section
    if(btnClose)
    btnClose.addEventListener('click', ()=>{
        sectLogin.classList.add('hide');
        changeBlur();
    });
    
    // Formulario enviado login
    form.addEventListener('submit', (e)=>{
        e.preventDefault();

        // Se deshabilita el botón de submit
        btnSubmit.disabled = true;
        btnSubmit.classList.add('btnDisabled');

        // Señala donde se van a colocar las alerts
        const divAlerts = document.querySelector('#alertsLogin');

        // Inputs de HTML
        //const teléfonoInput = document.querySelector('#telRegister');
        const inEmail = document.querySelector('#emailLogin');
        const inPassword = document.querySelector('#passwordLogin');

        // data formulario HTML SANITIZADO por DOMPurify
        const dataForm = {
            email: DOMPurify.sanitize(inEmail.value.trim()),
            password: DOMPurify.sanitize(inPassword.value)
        };
        
        // Validar data antes de enviarlos
        setAlert('clear'); // Limpia las alerts
        const emailRegular = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Formar regular de un email
        
        if (!emailRegular.test(dataForm.email))        setAlert('error', 'El correo electrónico no es válido');
        if (dataForm.email.length > 50)                 setAlert('error', 'El correo es demasiado largo');
        if (dataForm.password.length < 8)              setAlert('error', 'La cantidad de caracteres de la contraseña debe ser mayor a 7');
        
        // Si existen alerts mostrarlas
        if(hasAlerts('error')) {
            showAlerts(divAlerts, 3000);
            setTimeout(()=>{
                btnSubmit.disabled = false;
                btnSubmit.classList.remove('btnDisabled');
            }, 5000);
            return;
        }

        // data a enviar a la API
        const dataToAPI = new FormData();
        dataToAPI.append('email', dataForm.email);
        dataToAPI.append('password', dataForm.password);
        
        // URL
        const urlAPI = url + '/api/login';
        
        // Opciones Fetch
        const options = {
            method: 'POST',
            body: dataToAPI,
        }
        
        // Mensaje de espera
        setAlert('clear');
        setAlert('info', 'Por favor espera');
        showAlerts(divAlerts, -1); // Dura infinito hasta que se borre
        
        // Enviar petición API
        fetch(urlAPI, options)
        .then(response => response.json())
        .then(data => {
            // Si hubo un registro exitoso
            setAlert('clear'); // Limpia las alerts
            
            if(data['result'] == "true"){
                // Login Exitoso
                setAlert('success', 'Has iniciado sesión correctamente')
                showAlerts(divAlerts, 5000);
                setTimeout(() => location.reload(), 3000);
            }else{
                // Login erroneo
                btnSubmit.disabled = false;
                btnSubmit.classList.remove('btnDisabled');
                
                if (Array.isArray(data.error)) data.error.forEach(error =>  {
                    setAlert('error', error);
                    
                    if(error === 'La cuenta no ha sido confirmada. Reenvía el correo de confirmación en la opciones de abajo') forwardConfirmEmail(dataForm.email);
                    showAlerts(divAlerts, 7000);
                });
                else {
                    setAlert('error', 'Hubo un problema');
                    showAlerts(divAlerts, 5000);
                }
            }
        })
        .catch(error => {
            // Muestra el error
            btnSubmit.disabled = false;
            btnSubmit.classList.remove('btnDisabled');

            setAlert('clear');
            setAlert('error', error)
            showAlerts(divAlerts, 5000);
        });
    });
}