function initBurgerMenu() {
    const body = document.querySelector('body > :first-child');
    const btnAbrir = document.querySelector('#burgerMenu');
    const btnClose = document.querySelector('#closeMenu');
    const header = document.querySelector('.header');
    
    btnAbrir.addEventListener('click', ()=>{
        header.classList.add("headerShow");
    });

    btnClose.addEventListener('click', ()=>{
        header.classList.remove("headerShow");
    });
}