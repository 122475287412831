function optionsAdmin(){
    const btnEliminar = document.querySelector('#adminEliminarUsuario');
    const btnDesbloquear = document.querySelector('#adminDesbloquearUsuario');
    const btnBloquear = document.querySelector('#adminBloquearUsuario');

    const btnAceptarCita = document.querySelector('#adminAceptarCita');
    const btnCancelCita = document.querySelector('#adminCancelCita');

    if(btnAceptarCita != null) acceptCita(btnAceptarCita);
    if(btnCancelCita != null) cancelCita(btnCancelCita);
    
}